/* eslint-disable no-confusing-arrow */
import { forwardRef, useMemo, useState, useContext, memo } from 'react';

import Text from 'components/Typography/Text';
import Tag from 'components/DataDisplay/Tag';
import StarIcon from 'assets/icons/ic_star_gray_sharp.svg';
import GiftBoxIcon from 'assets/icons/ic_gift_box_xs.svg?url';
import CalendarIcon from 'assets/icons/ic_calendar_xs.svg?url';
import hospitalDetailSquareImg from 'assets/images/img_hospital_detail_square.png';
import BookmarkSvgIcon from 'assets/icons/ic_bookmark';

import { calcCareerYear, calcDiscountRate } from 'utils/calculate';
import { getShortAddress, getFormattedNumberKo, getFormattedNumber, getFormattedDistance } from 'utils/format';
import LightChip from 'components/DataDisplay/LightChip';
import { LocationContext } from 'contexts/LocationContext';
import { useRouter } from 'next/router';
import { FunnelContext } from 'contexts/FunnelContext';
import FixSwipeable from 'components/atoms/FixSwipeable';
import InformationIcon from 'assets/icons/ic_information.svg?url';
import { Popover } from 'components/Overlay/Popover';
import { composeFunnelledQueryString } from 'utils';
import { LightChipStyleType } from 'components/DataDisplay/LightChip/types';
import { redirectImageUrl } from 'utils/url-helper';
import { Skeleton } from '@material-ui/lab';
import Spacer from 'components/Layout/Spacer';
import useUserAgent from 'hooks/useUserAgent';
import { MINIMUM_VERSION_GRAY_HOSPITAL } from 'types/constants';
import { postCommandMessage, PostMessageCommandKey } from 'libs/bridge';
import { UserContext } from 'contexts/UserContext';
import { addHttpOnURL } from 'utils/addHttp';
import { useToastContext } from 'contexts/ToastContext';
import useNavigation, { PageName } from 'hooks/useNavigation';
import { ActionTypeEnum, ToggleBookmarkMutation, useToggleBookmarkMutation } from 'graphql/generated/graphql';
import Color from 'styles/colors';

import { apolloClinicNewLink } from 'graphql/apollo';
import { MedicalServicePriceDetails } from 'model/graphql';
import * as styles from './styles';
import { MedicalService } from './types';

export interface MedicalServiceCardProps {
  medicalService: MedicalService;
  familyCategoryIds?: string[];
  depth?: number;
  isBookmarked?: boolean;
  index?: number;
  onBeforeCardClicked?: (medicalService: MedicalService, index: number) => void;
  onClickBookmark?: (
    medicalService: MedicalService,
    isBookmarked: boolean,
    index: number,
    onClickEvent: Function
  ) => void;
}

interface BadgeType {
  type: LightChipStyleType;
  label: string;
  icon: string;
}

export interface PriceData {
  discountRate: string;
  price: string | null;
  categoryName: string;
  minPrice: boolean;
  priceSource: string;
}

interface BadgeType {
  type: LightChipStyleType;
  label: string;
  icon: string;
}

function parseTags(tags: string[]) {
  return tags.map((tag) => {
    const major = tag.substring(0, 1) === '_';
    return {
      major,
      tag: major ? tag.substring(1) : tag,
    };
  });
}

function getMinPriceDetailsByTargetCategory(
  priceDetails: MedicalServicePriceDetails[],
  familyCategoryIds: string[],
  depth: number
) {
  const duplicateCheckList = {};
  const filteredPriceDetails = [...priceDetails]
    .sort((a, b) =>
      a.medicalCategoryDepth !== b.medicalCategoryDepth
        ? depth < 3
          ? a.medicalCategoryDepth - b.medicalCategoryDepth
          : b.medicalCategoryDepth - a.medicalCategoryDepth
        : a.price - b.price
    )
    ?.filter((priceDetail: MedicalServicePriceDetails) => {
      if (!priceDetail.price) return false;
      if (duplicateCheckList[priceDetail.medicalCategoryName]) return false;

      duplicateCheckList[priceDetail.medicalCategoryName] = true;
      return familyCategoryIds.includes(priceDetail.medicalCategoryId);
    });
  return filteredPriceDetails.slice(0, depth < 3 ? 2 : 1);
}

const MedicalServiceCard = forwardRef<HTMLDivElement, MedicalServiceCardProps>((props, ref) => {
  const { medicalService, onBeforeCardClicked, familyCategoryIds, depth, index, onClickBookmark, isBookmarked } = props;
  const router = useRouter();
  const { coordinate } = useContext(LocationContext);
  const { funnel } = useContext(FunnelContext);
  const { isSignedIn } = useContext(UserContext);
  const { showError, showInfo } = useToastContext();
  const [informationPopoverOpenList, setInformationPopoverOpenList] = useState<string[]>([]);
  const { isBrowser, checkMiniumAppVersion } = useUserAgent();
  const { routerPush } = useNavigation();

  const [toggleBookmarkMutation] = useToggleBookmarkMutation({
    client: apolloClinicNewLink,
    fetchPolicy: 'no-cache',
  });

  const onClickEventWithStopPropagation = (e: React.MouseEvent, onClickEvent: Function) => {
    e.stopPropagation();
    onClickEvent();
  };

  const toggleBookmark = async (isBookmarked: boolean): Promise<ToggleBookmarkMutation | false> => {
    try {
      const toggleBookmarkResult = await toggleBookmarkMutation({
        variables: { eventId: medicalService.clinicEventId, value: isBookmarked },
      });
      return toggleBookmarkResult.data;
    } catch (e) {
      console.error('error', e);
      return false;
    }
  };

  const handleBookmarkClicked = () => {
    onClickBookmark?.(medicalService, !isBookmarked, index, async () => {
      if (isSignedIn) {
        const toggleBookmarkResult = await toggleBookmark(!isBookmarked);
        if (!toggleBookmarkResult) {
          showError('찜하기를 실패했어요.');
        } else {
          showInfo(
            `"${medicalService?.medicalServiceSummary?.clinicEventName}" 이벤트${
              toggleBookmarkResult.toggleBookmark.selected ? '를 찜했어요.' : ' 찜하기를 취소했어요.'
            }`
          );
        }
      } else if (isBrowser) {
        routerPush(PageName.signIn);
      } else {
        postCommandMessage(PostMessageCommandKey.auth);
      }
    });
  };

  const onClickCardAction = () => {
    onBeforeCardClicked?.(medicalService, index);

    const queryString = composeFunnelledQueryString(funnel, router.asPath);

    if (medicalService.actionType === ActionTypeEnum.CallRequest) {
      if (medicalService.clinicEventId) {
        router.push(`/events/${medicalService.clinicEventId}?${queryString}`);
      } else {
        showError('EventID를 찾을 수 없습니다.');
      }
    } else if (medicalService.actionType === ActionTypeEnum.OpenUrl) {
      if (isBrowser) {
        window.open(addHttpOnURL(medicalService.url));
      } else if (checkMiniumAppVersion(MINIMUM_VERSION_GRAY_HOSPITAL)) {
        window.location.href = `goodoc://app/clinicHospital/${medicalService.id}`;
      } else {
        window.location.href = `goodoc://app/web?uri=${encodeURIComponent(addHttpOnURL(medicalService.url))}&title=${
          medicalService?.medicalServiceSummary?.hospitalName
        }&headerShown=true`;
      }
    } else if (medicalService.actionType === ActionTypeEnum.Reservation) {
      router.push(
        `/medical-service/${medicalService.id}/reservation?${queryString}&hospitalId=${medicalService.hospitalId}`
      );
    } else {
      showError(`Action Type이 잘못 지정 되었습니다. ActionType: ${medicalService.actionType}`);
    }
  };

  function popoverOpen(categoryName, open) {
    setInformationPopoverOpenList((prev) => {
      const newPopover = { ...prev };
      newPopover[categoryName] = open;
      return newPopover;
    });
  }

  const isOpenUrl = medicalService.actionType === ActionTypeEnum.OpenUrl;

  const badge = useMemo<BadgeType | null>(() => {
    switch (medicalService.actionType) {
      case ActionTypeEnum.CallRequest:
        return { type: 'primary', label: '상담신청', icon: GiftBoxIcon };
      case ActionTypeEnum.Reservation:
        return { type: 'green', label: '예약신청', icon: CalendarIcon };
      default:
        return null;
    }
  }, [medicalService]);

  const infoData = useMemo(() => {
    const { medicalServiceSummary: summary } = medicalService;
    return {
      title: (isOpenUrl ? summary?.hospitalName : medicalService?.displayName) || '',
      thumbnail: medicalService?.thumbnailUrl,
      location: (isOpenUrl ? getShortAddress(summary?.address || '') : summary?.hospitalName) || '',
      distance: getFormattedDistance({
        currentLocation: coordinate,
        latitude: summary?.latitude,
        longitude: summary?.longitude,
      }),
      reviewsCount: getFormattedNumber(summary?.reviewsCount || 0, 9999),
      reviewScore: summary?.ratingMean || '-',
      universityIcon: summary?.mainDoctorEducation?.school?.logoUrl,
      university: summary?.mainDoctorEducation?.school?.name,
      careerYear: summary?.openedDate ? `개원 ${calcCareerYear(summary.openedDate)}년` : '',
      tags: parseTags(summary?.medicalCategoryNames || []),
    };
  }, [medicalService, isOpenUrl, coordinate]);

  const priceData: PriceData[] = useMemo(() => {
    if (!isOpenUrl) {
      const { marketPrice, price, originalPrice, textPrice } = medicalService;
      const discountRate = calcDiscountRate(price, originalPrice);
      return [
        {
          discountRate: marketPrice || discountRate === '0' ? null : `${discountRate}%`,
          price: marketPrice ? textPrice : !price ? null : getFormattedNumberKo(price),
          categoryName: '',
          minPrice: false,
          priceSource: '',
        },
      ];
    }

    const { medicalServiceSummary: summary } = medicalService;

    if (!familyCategoryIds || !summary?.priceDetails) {
      return [];
    }

    const priceDetails = getMinPriceDetailsByTargetCategory(
      summary?.priceDetails as MedicalServicePriceDetails[],
      familyCategoryIds,
      depth
    );
    return priceDetails.map(({ price, medicalCategoryName, priceSource }) => ({
      discountRate: '',
      price: price ? `${getFormattedNumberKo(price)}~` : null,
      categoryName: medicalCategoryName,
      minPrice: true,
      priceSource,
    }));
  }, [isOpenUrl, familyCategoryIds, medicalService, depth]);

  return (
    <div className={styles.container} onClick={onClickCardAction} ref={ref}>
      <div className={styles.infoContent}>
        <div className={styles.info}>
          {badge && (
            <div className={styles.chip}>
              <LightChip.WithImage
                size="xSmall"
                styleType={badge.type}
                leftImage={<img src={badge.icon} className={styles.icon('xsLogo')} alt="xsLogo" />}
              >
                {badge.label}
              </LightChip.WithImage>
            </div>
          )}
          <Text.Body1Bold className={styles.title}>{infoData.title}</Text.Body1Bold>
          <div className={styles.locationRow}>
            {medicalService.actionType !== ActionTypeEnum.Reservation && (
              <>
                <Text.Body3 color={Color.Gray[60]}>{infoData.distance}</Text.Body3>
                <Text.Body3 color={Color.Gray[30]}> | </Text.Body3>
              </>
            )}
            <Text.Body3 className={styles.locationGroup} color={Color.Gray[60]}>
              {infoData.location}
            </Text.Body3>
            {!isOpenUrl && (
              <>
                <Text.Body3 color={Color.Gray[30]}> | </Text.Body3>
                <Text.Body3 className={styles.reviewGroup}>
                  <StarIcon className={styles.starIcon} />
                  <span className="reviewScore">{infoData.reviewScore}</span>
                  <span>{`(${infoData.reviewsCount})`}</span>
                </Text.Body3>
              </>
            )}
          </div>
          {isOpenUrl && (
            <div className={styles.hospitalRow}>
              <Text.Body3 className={styles.reviewGroup}>
                <StarIcon className={styles.starIcon} />
                <span className="reviewScore">{infoData.reviewScore}</span>
                <span>{`(${infoData.reviewsCount})`}</span>
              </Text.Body3>
              {infoData.university && (
                <>
                  <Text.Body3 color={Color.Gray[30]}> | </Text.Body3>
                  {infoData.universityIcon && (
                    <img src={infoData.universityIcon} className={styles.icon('university')} alt="university" />
                  )}
                  <Text.Body3>{infoData.university}</Text.Body3>
                </>
              )}
              {infoData.careerYear && (
                <>
                  <Text.Body3 color={Color.Gray[30]}> | </Text.Body3>
                  <Text.Body3 color={Color.Gray[60]}>{infoData.careerYear}</Text.Body3>
                </>
              )}
            </div>
          )}
          {!!infoData.tags.length && (
            <FixSwipeable>
              <div className={styles.tagRow}>
                {infoData.tags.map((tag, index) => (
                  <Tag key={index} size="xxSmall" styleType={tag.major ? 'green' : 'gray'}>
                    {tag.tag}
                  </Tag>
                ))}
              </div>
            </FixSwipeable>
          )}
        </div>
        {(medicalService.actionType !== ActionTypeEnum.Reservation || infoData.thumbnail) && (
          <div className={styles.thumbnail}>
            <img
              src={redirectImageUrl(infoData.thumbnail, 240) || hospitalDetailSquareImg}
              className={styles.thumbnailImg}
              alt="thumbnail"
            />
          </div>
        )}
      </div>

      <div className={styles.priceContent}>
        <div className={styles.priceColumn}>
          {priceData.map(({ price, discountRate, categoryName, priceSource }, index) => (
            <div key={index} className={styles.priceRow}>
              {categoryName && <span className={styles.categoryName}>{categoryName}</span>}
              <Text.Body1Bold className={styles.price}>{price || '병원에 문의하기'}</Text.Body1Bold>
              {discountRate && <Text.Body1Bold className={styles.discountRate}>{discountRate}</Text.Body1Bold>}
              {priceSource === 'hira' && (
                <span className={styles.informationGroup}>
                  <button
                    className={styles.informationButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      popoverOpen(categoryName, true);
                    }}
                  >
                    <img src={InformationIcon} className={styles.icon('information')} alt="" />
                  </button>
                  {informationPopoverOpenList[categoryName] && (
                    <Popover
                      layout={styles.informationPopover}
                      onClose={() => {
                        popoverOpen(categoryName, false);
                      }}
                    >
                      <Text.Body3>
                        건강정보심사평가원에서 제공하는 가격으로 실제와 다소 차이가 있을 수 있습니다. 정확한 가격은
                        병원에 문의해 주세요.
                      </Text.Body3>
                    </Popover>
                  )}
                </span>
              )}
            </div>
          ))}
        </div>
        {medicalService.clinicEventId && (
          <button
            className={styles.bookmarkButton}
            onClick={(e) => onClickEventWithStopPropagation(e, () => handleBookmarkClicked())}
          >
            <BookmarkSvgIcon className={styles.bookmarkIcon(isBookmarked)} filled={isBookmarked} />
          </button>
        )}
      </div>
    </div>
  );
});

export const MedicalServiceCardSkeleton = () => (
  <div className={styles.cellSkeleton}>
    <div className={styles.contentSkeleton}>
      <div className={styles.leftColumnSkeleton}>
        <Skeleton variant="rect" width="100%" height={22} />
        <Spacer height={6} />
        <Skeleton variant="rect" width={120} height={34} />
        <Spacer height={6} />
        <Skeleton variant="rect" width="100%" height={16} />
        <Spacer height={6} />
      </div>
      <Skeleton variant="rect" width={80} height={80} className={styles.thumbnailSkeleton} />
    </div>
    <div className={styles.cellBottomSkeleton}>
      <Skeleton variant="rect" width={92} height={23} />
      <Skeleton variant="rect" width={18} height={23} />
    </div>
  </div>
);

export const MedicalServiceCardsSkeleton = () => (
  <>
    {Array(6)
      .fill(null)
      .map((_, index) => (
        <MedicalServiceCardSkeleton key={index} />
      ))}
  </>
);

export default memo(MedicalServiceCard);
