import Color from 'styles/colors';
import { css } from '@emotion/css';
import { Weight } from 'styles/font';

export const row = css`
  display: flex;
  flex-direction: row;

  align-items: center;
  gap: 0.25rem;
  color: ${Color.Gray['50']};
`;

export const column = css`
  display: flex;
  flex-direction: column;
`;

export const bookmarkButton = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
`;

export const container = css`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background-color: ${Color.White};
  padding: 1rem 1.25rem;
  width: 100%;
  gap: 10px;
  border-bottom: 1px solid ${Color.Gray['20']};
`;

export const infoContent = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.625rem;
`;

export const info = css`
  ${column}
  max-width: calc(100% - 5rem);
  overflow: hidden;
`;

export const thumbnail = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: flex-end;
  margin-top: 0.25rem;
`;

export const chip = css`
  min-width: 3.75rem;
  margin-bottom: 0.125rem;
`;

export const title = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Color.Gray['100']};
  margin-bottom: 0.25rem;
`;

export const locationGroup = css`
  ${row}
  margin-bottom: 0rem;
  align-items: initial;
`;
export const locationRow = css`
  ${row}
  margin-bottom: 6px;
`;

export const reviewGroup = css`
  display: flex;
  flex-direction: row;
  gap: 0.125rem;
  color: ${Color.Gray['80']};
  align-items: center;
  font-weight: ${Weight.Regular};
  & .reviewScore {
    font-weight: ${Weight.Bold};
  }
`;

export const starIcon = css`
  fill: ${Color.Gray[80]};
`;

export const hospitalRow = css`
  ${row}
  margin-bottom: 0.375rem;
`;

export const tagRow = css`
  ${row}
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

export const priceContent = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 6px;
  align-items: center;
`;

export const thumbnailImg = css`
  width: 82px;
  height: 82px;
  min-width: 82px;
  min-height: 82px;
  border-radius: 4px;
  object-fit: cover;
`;

const iconSize = {
  location: '0.875rem',
  university: '0.75rem',
  ratingStar: '0.5rem',
  bookmark: '1.125rem',
  xsLogo: '0.6rem',
  information: '0.75rem',
};

export const icon = (iconType: keyof typeof iconSize) => css`
  width: ${iconSize[iconType]};
  height: ${iconSize[iconType]};
`;
export const bookmarkIcon = (filled: boolean) => css`
  width: ${iconSize.bookmark};
  height: ${iconSize.bookmark};
  fill: ${filled ? Color.Red['60'] : Color.Gray['90']};
`;

export const discountRate = css`
  color: ${Color.Primary};
  font-weight: ${Weight.Bold};
`;

export const categoryName = css`
  font-weight: ${Weight.Regular};
  color: ${Color.Gray['80']};
  font-size: 0.625rem;
  line-height: 0.75rem;
`;

export const price = css`
  font-weight: ${Weight.Bold};
  color: ${Color.Gray['100']};
`;

export const minPrice = css`
  font-weight: ${Weight.Regular};
  font-size: 0.625rem;
  line-height: 0.75rem;
  color: ${Color.Gray['60']};
`;
export const priceRow = css`
  ${row}
  gap: 0.375rem;
  margin-bottom: 0;
  & span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.125rem;
    margin-bottom: 0;
  }
`;

export const priceColumn = css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const informationGroup = css`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
`;

export const informationButton = css`
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  &:focus {
    outline: none;
  }
`;

export const informationPopover = css`
  position: absolute;
  top: 0.9rem;
  transform: translateX(20px);
  background: ${Color.White};
  color: ${Color.Gray['80']};
  border: 1px solid ${Color.Gray['50']};
  width: 260px;
  border-radius: 6px;
  padding: 0.625rem 1.25rem;
  z-index: 1;
`;

/// /// skeleton
export const cellSkeleton = css`
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  width: 100%;
  border-bottom: 1px solid ${Color.Gray['20']};
`;

export const contentSkeleton = css`
  display: flex;
  justify-content: space-between;
`;

export const thumbnailSkeleton = css`
  border-radius: 6px;
`;

export const leftColumnSkeleton = css`
  flex: 1;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;

export const cellBottomSkeleton = css`
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;
