import React, { useCallback, useEffect } from 'react';
import 'react-virtualized/styles.css';
import MedicalServiceCard, { MedicalServiceCardsSkeleton } from 'components/DataDisplay/Card/MedicalService';
import { InView } from 'react-intersection-observer';
import EmptyPlaceholder from 'components/Layout/EmptyPlaceholder';
import { MedicalService } from 'components/DataDisplay/Card/MedicalService/types';
import { useBookmarkIds } from 'hooks/useBookmark';
import * as styles from './styles';

interface IMedicalServiceListProps {
  medicalServices: MedicalService[];
  familyCategoryIds?: string[];
  depth?: number;
  needsLoadingMore?: boolean;
  needsListHidden?: boolean;
  isLoading?: boolean;
  isInitialDataFetched?: boolean;
  onLoadMoreButtonClicked?: () => void;
  onBeforeCardClicked?(medicalService: MedicalService, index: number): void;
  onBeforeBookmarkClicked?(medicalService: MedicalService, isBookmarked: boolean, index: number): void;
  onEmpty?(): void;
  onListImpression?(index: number): void;
  emptyMessage?: string;
  emptyDescription?: string;
}

export default function MedicalServiceList({
  medicalServices,
  familyCategoryIds,
  depth,
  needsLoadingMore = false,
  needsListHidden = false,
  isLoading = false,
  isInitialDataFetched = false,
  onLoadMoreButtonClicked,
  onBeforeCardClicked,
  onBeforeBookmarkClicked,
  onEmpty,
  onListImpression,
  emptyMessage = '검색 결과가 없어요.',
  emptyDescription = '고객님이 계신 지역에 서비스를 준비중입니다.\n다른 지역을 둘러보시는건 어떠신가요?',
}: IMedicalServiceListProps) {
  const isEmpty = isInitialDataFetched && !isLoading && medicalServices?.length === 0;

  const { bookmarkClinicEventIds, refetchBookmarkIds } = useBookmarkIds();
  const onClickBookmarkWrapper = useCallback(
    async (medicalService: MedicalService, isBookmarked: boolean, index: number, onClickEvent: Function) => {
      onBeforeBookmarkClicked?.(medicalService, isBookmarked, index);
      onClickEvent().then(() => {
        refetchBookmarkIds();
      });
    },
    [onBeforeBookmarkClicked, refetchBookmarkIds]
  );

  useEffect(() => {
    if (isEmpty) {
      onEmpty?.();
    }
  }, [onEmpty, isEmpty]);

  return (
    <>
      {!!medicalServices?.length && !needsListHidden ? (
        medicalServices.map(
          (medicalService, idx) =>
            medicalService?.medicalServiceSummary && (
              <InView
                threshold={0}
                delay={100}
                skip={idx % 10 !== 9}
                key={medicalService.id}
                onChange={(inView) => {
                  if (inView) {
                    onListImpression?.(idx);
                  }
                }}
              >
                {({ ref }) => (
                  <MedicalServiceCard
                    ref={ref}
                    medicalService={medicalService}
                    depth={depth}
                    familyCategoryIds={familyCategoryIds}
                    index={idx}
                    isBookmarked={bookmarkClinicEventIds?.includes(medicalService.clinicEventId)}
                    onBeforeCardClicked={onBeforeCardClicked}
                    onClickBookmark={onClickBookmarkWrapper}
                  />
                )}
              </InView>
            )
        )
      ) : isEmpty ? (
        <EmptyPlaceholder message={emptyMessage} description={emptyDescription} />
      ) : (
        !needsListHidden && <MedicalServiceCardsSkeleton />
      )}
      {needsLoadingMore && !needsListHidden && (
        <div className={styles.loadMoreCell}>
          <div
            className={styles.loadMoreButton(isLoading)}
            onClick={() => {
              if (isLoading) return;
              onLoadMoreButtonClicked?.();
            }}
          >
            더 보기
          </div>
        </div>
      )}
    </>
  );
}
