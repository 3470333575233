import React, { useEffect, useRef } from 'react';

type Props = React.PropsWithChildren<{
  onClose: () => void;
  layout: string;
}>;

export const Popover = React.memo<Props>((props) => {
  const { onClose, children, layout } = props;

  const settingsWindowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const pageClickEvent = (e: any) => {
      if (!settingsWindowRef?.current?.contains(e.target)) {
        e.stopPropagation();
        onClose();
      }
    };

    window.addEventListener('click', pageClickEvent, true);

    return () => {
      window.removeEventListener('click', pageClickEvent, true);
    };
  }, []);

  return (
    <div ref={settingsWindowRef} className={layout}>
      {children}
    </div>
  );
});
