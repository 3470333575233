import React, { PropsWithChildren } from 'react';
import { LightChipProps } from './types';
import * as styles from './styles';

const LightChip = ({
  layout = '',
  children,
  size = 'xSmall',
  styleType = 'primary',
  onClick = () => null,
  ...props
}: PropsWithChildren<LightChipProps>) => (
  <span className={styles.lightChip(size, styleType, layout)} onClick={onClick} {...props}>
    {children}
  </span>
);

export default LightChip;
