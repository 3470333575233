import Text from 'components/Typography/Text';
import NotFoundImage from 'assets/images/img_notFound.svg?url';
import Button from 'components/Forms/Button';
import useNavigation, { PageName } from 'hooks/useNavigation';
import { AnalyticsContext } from 'contexts/AnalyticsContext';
import { useContext } from 'react';
import * as styles from './styles';

interface EmptyPlaceholderProps {
  message: string;
  description?: string;
  showHomeButton?: boolean;
  trackingEventName?: string;
}

const EmptyPlaceholder = (props: EmptyPlaceholderProps) => {
  const { message, description, trackingEventName, showHomeButton: needButton = false } = props;
  const { routerReplace } = useNavigation();
  const { trackEvent } = useContext(AnalyticsContext);

  const onClickHomeButton = () => {
    if (trackingEventName) trackEvent(trackingEventName);
    routerReplace(PageName.root);
  };

  return (
    <section className={styles.section}>
      <img src={NotFoundImage} alt="not found" />
      <Text.Body2Bold className={styles.message}>{message}</Text.Body2Bold>
      {description && <Text.Body3Medium className={styles.description}>{description}</Text.Body3Medium>}
      {needButton && (
        <Button styleType="primaryOutline" size="large" layout={styles.fullButton} onClick={onClickHomeButton}>
          시술혜택 둘러보기
        </Button>
      )}
    </section>
  );
};

export default EmptyPlaceholder;
