import { css } from '@emotion/css';
import Color from 'styles/colors';

export const section = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 24rem;

  padding: 0 20px;
`;

export const message = css`
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const description = css`
  white-space: pre-wrap;
  color: ${Color.Gray['60']};
  text-align: center;
`;

export const fullButton = css`
  margin-top: 26px;
  height: 56px;
  width: 100%;
`;
