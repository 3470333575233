import { css } from '@emotion/css';
import Color from 'styles/colors';

export const message = () => css`
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const description = () => css`
  white-space: pre-wrap;
  color: ${Color.Gray['60']};
  text-align: center;
`;

export const loadMoreCell = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 20px;
`;

export const loadMoreButton = (disabled: boolean) => css`
  height: 47px;
  line-height: 47px;
  text-align: center;
  flex: 1;
  background-color: ${Color.Gray['20']};
  color: ${disabled ? Color.Gray['40'] : Color.Gray['80']};
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
`;

export const placeholderBottom = css`
  margin-bottom: 46px;
`;
